<template>
  <v-skeleton-loader type="list-item-three-line" v-if="isLoading" />

  <Card title="Acesso rápido" color="primary" v-else>
    <template v-slot:content>
      <div
        :class="['quick-access', `color--${menu.color}`]"
        :key="index"
        v-for="(menu, index) in menus"
        @click="redirect(menu.path, menu.external)"
      >
        <span class="quick-access--icon">
          <i :class="menu.icon" />
        </span>
        <span class="quick-access--text">{{ menu.text }}</span>
      </div>
    </template>
  </Card>
</template>

<script>
import { configureRequest } from '@/helpers/configureRequest';
import { DashboardService } from '@/services/api/dashboard';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    customerName: {
      type: String,
      require: true
    },
    customerId: {
      type: String,
      require: true
    },
    contractId: {
      type: String,
      require: true
    },
    hasHealthManagement: {
      type: Boolean,
      require: true,
      default: false
    }
  },

  data: () => ({
    menus: []
  }),

  components: {
    Card: () => import('./common/card-template')
  },

  created() {
    this.createMenus();
  },

  methods: {
    createMenus() {
      const invoiceParams = {
        customerName: this.customerName,
        customerId: this.customerId,
        contractId: this.contractId
      };

      const menus = [
        {
          color: 'info',
          icon: 'fa-solid fa-arrows-down-to-people',
          path: `movimentacoes/list/${this.customerId}`,
          text: 'Movimentações',
          external: false,
          visible: true
        },
        {
          color: 'success',
          icon: 'fa-solid fa-hand-holding-dollar',
          path: configureRequest('faturas', invoiceParams),
          text: 'Faturas',
          external: false,
          visible: true
        },
        {
          color: 'warning',
          icon: 'fa-solid fa-ranking-star',
          path: null,
          text: 'Gestão de saúde',
          external: true,
          visible: this.hasHealthManagement
        },
        {
          color: 'warning',
          icon: 'fa-solid fa-database',
          path: 'relatorios',
          text: 'Relatórios',
          external: false,
          visible: !this.hasHealthManagement
        }
      ];

      this.menus = menus.filter(menu => menu.visible === true);
    },

    redirect(path, external) {
      if (external) {
        this.redirectWellbe();
        return;
      }

      if (path.includes('https')) {
        window.open(path, '_blank');
      } else {
        this.$router.push(path);
      }
    },

    async redirectWellbe() {
      try {
        const service = new DashboardService();
        const { status, data } = await service.getHealthManagementUrl();

        if (status === 200) {
          window.open(data.url, '_parent');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    contractId() {
      this.createMenus();
    }
  }
};
</script>

<style lang="scss">
.quick-access {
  cursor: pointer;
  padding: 0 1.5rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-left: 2px solid;

  &:last-child {
    margin-bottom: 0;
  }

  &--icon {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;

    i {
      font-size: 18px;
    }

    &:before {
      background-color: currentColor;
      bottom: 0;
      border-radius: inherit;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.theme--light {
  .quick-access {
    &--text {
      color: $gray--40;
    }

    &:hover {
      background: $gray--10;
    }
  }
}

.theme--dark {
  .quick-access {
    &--text {
      color: $gray--20;
    }

    &:hover {
      background: $bg-dark-active;
    }
  }
}
</style>
